/*
   @page Device manager
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import MUIDataTable from "mui-datatables";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FormControl from '@mui/material/FormControl';
import EditIcon from '@material-ui/icons/Edit';


/*
  @const Page styles
*/
const styles = {
  root: {
    '& .Mui-selected': {
      backgroundColor: 'black',
      color: '#ffa726',
    }
  },
  rowPerPage:
  {
    minHeight: "40px",
    height: "40px"
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#ffa726"
    }
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    margin: 1,
    minWidth: 120,
    maxWidth: 300,
  },
  popupHalfWidth: {
    width: "100%",
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: 5,
  },
  setFieldHeight: {
    minHeight: "48px",
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function Device manager page logic
*/
const useStyles = makeStyles(styles);


export default function TableList() {
  /*
    variables start
  */
    const classes = useStyles();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
  const [tableData, setTableData] = useState(false);  
  const [editKeyName, setEditKeyName] = useState("");
  const [editKeyLimit, setEditKeyLimit] = useState(0);
  const [editId, setEditId] = useState(0);
  
  const [keyName, setKeyName] = useState("");
  const [keyLimit, setKeyLimit] = useState(10);  
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [deleteKeyDialog, setKeyDeleteDailog] = React.useState(false);  
  const [id, setId] = React.useState(0);  
  const [rowPerPage, setRowPerPage] = useState(15);
  const [updateKeyDialog, setUpdateKeyDailog] = React.useState(false); 




  const columns = [
    {
      name: "ID",
      label: "ID",
      options: {        
        sort: true,
        //hint: "Sort by ID",
      },
    },
    {
      name: "Name",
      label: "Name",
      options: {        
        sort: true,
        //hint: "Sort by ID",
      },
    },
    {
      name: "Limit",
      label: "Limit",

      options: {        
        sort: true,
        //hint: "Sort by MID",
      },
    },
    {
      name: "API Key",
      label: "API Key",

      options: {        
        sort: true,
        //hint: "Sort by MID",
      },
    },    
    {
      name: "created at",
      label: "created at",
      options: {
        filter: true,
        sort: true,
      //  hint: "Sort by Email",
      },
    },
    {
      name: "Edit",
      label: "Edit",
      options: {
        filter: true,
        sort: false,
      //  hint: "Sort by Email",
      },
    },
    {
      name: "Delete",
      label: "Delete",
      options: {
        filter: true,
        sort: false,
      },
    },    
  ];
  const options = {
    selectableRows: 'none',  
    searchOpen: false,
    download: false,
    print: false,    
    filter: false,
    searchAlwaysOpen: false,   
  };
  
  const getApiKeyList = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'get_api_key_list', data, {
      headers: headers
    }).then(response => { 
      var keyArr = [];
      response.data.forEach((eachKey) => {
        console.log(eachKey);
        keyArr.push([eachKey.id,          
          eachKey.apikey_name,
          eachKey.apikey_limit,
          <div> {"**************************"+eachKey.apikey.substring(eachKey.apikey.length - 4)}
          {/* <i style={{marginLeft:"15px",cursor:"pointer"}} onClick={(e) => { }} class="fa fa-eye-slash" aria-hidden="true"></i> */}
          <i style={{marginLeft:"15px",cursor:"pointer"}} onClick={(e) => {navigator.clipboard.writeText(eachKey.apikey);setSuccessMsg("API Key Copied.");setSuccess(true); }} class="fa fa-clone" aria-hidden="true"></i></div>,
          eachKey.created_at,
          <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => {setUpdateKeyDailog(true);setEditKeyName(eachKey.apikey_name);setEditKeyLimit(eachKey.apikey_limit);setEditId(eachKey.id);}}><EditIcon style={{ height: '20px' }}></EditIcon></button>,
          <button id="reddeleteicon" style={{ backgroundColor: '#FF9800', color: '#FFFFFF', border: 'none' }} onClick={() => { setKeyDeleteDailog(true); setId(eachKey.id);}} ><DeleteForeverIcon></DeleteForeverIcon></button>,
        ]);
      });
      setTableData(keyArr);
      
    }).catch(error => {
      return error;
    });
  }

  /*
   @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  const handleDeleteId = () => {
    
    const data = {
      id:id
    };
    
    setTableData(false);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'delete_api_key', data, {
      headers: headers
    }).then(response => {
      setId(0);
      setKeyDeleteDailog(false);
      setSuccess(true);
      setSuccessMsg("Key Deleted Successfully!");
      getApiKeyList();
    }).catch(error => {
      getApiKeyList();
      setKeyDeleteDailog(false);
      setError(true);
      setErrorMsg("Some Error Occur!!");
      return error;
    });

  }

  const createKey = (action) => {
    let data = ""
    if(action == 'add')
    {
       data = {
        apikey_name: keyName,
        apikey_limit: keyLimit
      };
    }
    if(action == 'edit')
    {
       data = {
        apikey_name: editKeyName,
        apikey_limit: editKeyLimit,
        id:editId
      };
    }
    
    
    setTableData(false);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'create_key', data, {
      headers: headers
    }).then(response => {
      if(action == 'add')
      {
        setKeyLimit(10);
        setKeyName("");
        setSuccess(true);
        setSuccessMsg("Key Created Successfully!");
      }
      if(action == 'edit')
      {
        setEditKeyLimit(0);
        setEditKeyName("");
        setEditId(0);        
        setSuccessMsg("Key Updated Successfully!");
        setUpdateKeyDailog(false);
      }
      setSuccess(true);
      getApiKeyList();
    }).catch(error => {
      getApiKeyList();
      setError(true);
      setErrorMsg("Some Error Occur!!");
      return error;
    });

  }


  useEffect(() => {
    var permissionData = JSON.parse(localStorage.getItem("admin_permission"));    
    // setAddDeviceAccess(permissionData.add_device);
    const data = { 'current_page': 1, 'rowPerPage': rowPerPage };
    getApiKeyList();

  }, []);

 
  
 
  var permissionData = JSON.parse(localStorage.getItem("admin_permission"));  
  if(permissionData.access_device_manager == "0")
  {
    
      return (
        <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Security Key List</h4>
              </CardHeader>
  
              <CardBody>
                <GridContainer>
                  <GridItem xs={4} sm={12} md={4}></GridItem>
                  <center>
                    <h3><div>You do not have permission to access this page.</div> <div>Please contact an administrator.</div><div>Thank you.</div></h3>
  
                  </center>
                  <div id="transitInfo"></div>
                </GridContainer>
              </CardBody>
  
            </Card>
          </GridItem>
        </GridContainer>);
    
  }
  if (!tableData) { //Check if device data not come than display loading dialog
    return (
      <GridContainer>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Security Key List</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>
    
     

      {/* 
        Delete Device  popup start
       */}
      <Dialog
        open={deleteKeyDialog}
        keepMounted
        onClose={deleteKeyDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want to delete this Key?"}</DialogTitle>
        <DialogActions>
          <Button id="btnGreyCancel" onClick={() => { setKeyDeleteDailog(false);setId(0); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            No
          </Button>
          <Button id="btnRedDelete" onClick={()=>{ handleDeleteId(); }} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    
      {/* Clear Device  data popup start */}
      <GridItem xs={12} sm={12} md={12}>

        {/* 
            Display error message
          */}
        <Snackbar id="popuperror" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" style={{ whiteSpace: 'pre-line' }}>
            {errorMsg}
          </Alert>
        </Snackbar>

        {/* 
            Display success message
          */}
        <Snackbar id="popupsuccess" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {successMsg}
          </Alert>
        </Snackbar>

        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Security Key List</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>
          
            <GridContainer>
              {/* Select start date and end date */}

              <GridItem xs={12} sm={12} md={3}>
                <div style={{ height: "25px" }}>&nbsp;</div>
                <FormControl style={{ width: "100%" }}>
                  {/* Amount from textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    margin="full"
                    label="Name"
                    onChange={(e) => { setKeyName(e.target.value) }}
                    value={keyName}

                  />
                </FormControl>
              </GridItem>   
              <GridItem xs={12} sm={12} md={3}>
                <div style={{ height: "25px" }}>&nbsp;</div>
                <FormControl style={{ width: "100%" }}>
                  {/* Amount from textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    margin="full"
                    label="Limit"
                    onChange={(e) => { setKeyLimit(e.target.value) }}
                    value={keyLimit}

                  />
                </FormControl>
              </GridItem>   
              <GridItem xs={12} sm={12} md={3}>
                <center>
                  <div style={{ height: "25px" }}>&nbsp;</div>
                  <FormControl style={{ with: '100%' }} className={classes.popupHalfWidth} xs={12} sm={12} md={12}>
                    <Button variant="contained" color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', width: '100%', height: '55px' }} onClick={()=>{ createKey('add'); }} className={classes.button}>
                      Add
                    </Button>
                  </FormControl>
                </center>

              </GridItem>
         
             
            </GridContainer>
            <div id="deviceListtable" class="deviceListtable">           
                <MUIDataTable
              id="merchantListTable"              
              data={tableData}
              columns={columns}
              options={options}
            />
            </div>       
          </CardBody>
        </Card>
      </GridItem>

        {/* 
          Update Security Key start 
        */}
        <Dialog open={updateKeyDialog} onClose={() => { setUpdateKeyDailog(false) }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Update Security Key</DialogTitle>
          <DialogContent>


            <GridContainer>
            <GridItem xs={12} sm={12} md={12} >

            <TextField
              margin="dense"
              id="name"
              label="Name"
              fullWidth
              value={editKeyName}
              onChange={(e)=>{setEditKeyName(e.target.value);}}
            />

            </GridItem>
              <GridItem xs={12} sm={12} md={12} >

              <TextField
                  margin="dense"
                  id="LimitInput"
                  label="Limit"
                  fullWidth
                  value={editKeyLimit}
                  onChange={(e)=>{setEditKeyLimit(e.target.value);}}
                  inputProps={{ min: 0 }}
                />

              </GridItem>
            </GridContainer>

          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setUpdateKeyDailog(false);setEditKeyLimit(0);setEditKeyName("");setEditId(0); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Cancel
            </Button>
            <Button id="btnGreenAdd"  onClick={()=>{createKey('edit')}}  color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Update
            </Button>
          </DialogActions>
        </Dialog>

    </GridContainer>
  );
}
