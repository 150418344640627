import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import Merchant from "layouts/Merchant.js";
import Login from "layouts/Login.js";

import ForgotPassword from "merchantViews/ForgotPassword/ForgotPassword.js";
import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css?v=1.10.0";
import "assets/css/media-query.css"

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '0b854b6f-67f1-418a-9847-d4f359300331',
    clientToken: 'pubd3460a194cf231bad8f4e7eb4aaffadb',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'us5.datadoghq.com',
    service: 'magicpay-pos-dashboard',
    env: 'Dev_MagicPayPOS',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

window.domainUrl = "https://apidev.devapp.magicpaypos.com/";
window.apiUrl = window.domainUrl+"api/";    
window.tsepHandler = (eventType, event) =>{
  if( eventType == "TokenEvent")
         {                            
            window.eventData = event;              
            window.eventTypeData = eventType;                    
     }
      
}

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      
      <Route path="/login" component={Login} />
      <Route path="/forgot_password" component={ForgotPassword} />
      <Route path="/admin" component={Admin} />
      {/*<Route path="/rtl" component={RTL} />*/}
      <Route path="/" component={Merchant} />
      <Redirect from="/" to="/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);